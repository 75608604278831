import { ltjs } from '../ltjsInit';
import { getResourceURL, getUriParameter } from './urlUtil';
import { getLocale, getFormatLocale, ajax } from '../ltjsUtil';
import { ltjsModule } from '../ltjsEnvironment';
var isOpenUI = sas.ltjs.isOpenUI;
/** @private */
export var BASE_RESOURCE_LOCATION = isOpenUI ? 'sas/ltjslib' : 'ltjslib';
function _getConfigProperty(propChain, defaultValue) {
    var config = window['sap-ui-config'];
    if (!config || !propChain) {
        return defaultValue;
    }
    var props = propChain.split('.');
    var propValue = config;
    for (var _i = 0, props_1 = props; _i < props_1.length; _i++) {
        var propName = props_1[_i];
        if (!propValue) {
            return defaultValue;
        }
        // tslint:disable-next-line:no-unsafe-any
        propValue = propValue[propName];
    }
    return propValue === undefined ? defaultValue : propValue;
}
var deferredLoading = false;
// The Commons-HTML loader does nothing in the openUI case because this is handled by
//   the openui-framework ltjsLoaderUtil.
if (sas.ltjs.__deferredLoading) {
    deferredLoading = true;
}
//
// _wasmLoader object encapsulates the complexity of instantiating
// the wasm module. Instantiation relies on both the wasm binary/module/stream as well as
// the importObject.  We fetch the binary asynchronously, and we are also
// given the importObject asynchronously by the emscripten runtime. So this
// exposes four instantiation methods; one from a binary, one from a wasm Module,
// one from a wasm Promise<Response>, and one from an importsObject.
// All return the same wasmPromise, which resolves only when
// both the wasm and importObjects are received and the module is instantiated.
//
var _wasmLoader = (function () {
    var resolveWasm;
    //
    // resolveImports is called with import object received from the emscripten wasm
    // instantiation code.
    //
    var resolveImports;
    //
    // Instantiates wasm from the bytes returned by a fetch response.
    // This is used in the cases where streaming compilation is either fails, or
    // is not supported by the browser.
    //
    var instantiateFromResponse = function (responsePromise, imports) {
        return responsePromise
            .then(function (response) { return response.arrayBuffer(); })
            .then(function (buffer) { return WebAssembly.instantiate(buffer, imports); });
    };
    //
    // We rely on both the wasm (in any of its forms) and the imports object before
    // we can start instantiation.
    //
    var wasmPromise = Promise.all([
        new Promise(function (resolve) {
            resolveWasm = resolve;
        }),
        new Promise(function (resolve) {
            resolveImports = resolve;
        }),
    ]).then(function (values) {
        var wasm = values[0];
        var imports = values[1];
        if (wasm.binary) {
            //
            // Instantiated with wasm binary.
            //
            return WebAssembly.instantiate(wasm.binary, imports);
        }
        else if (wasm.responsePromise) {
            var wasmResponse_1 = wasm.responsePromise;
            if (WebAssembly.instantiateStreaming) {
                //
                // Streaming instantiation is supported, so let the browser do the work.
                //
                return WebAssembly.instantiateStreaming(wasm.responsePromise, imports).catch(function (error) {
                    //
                    // If streaming instantiation fails, then log an error, but try to recover
                    // by instantiating from the bytes.
                    //
                    console.error("LTJS load: ".concat(error));
                    return instantiateFromResponse(wasmResponse_1, imports);
                });
            }
            else {
                //
                // Streaming instantiation isn't supported, so let the fetch resolve with the
                // response and then instantiate from the bytes.
                //
                return instantiateFromResponse(wasm.responsePromise, imports);
            }
        }
        throw new Error('wasmPromise was resolved with an invalid result');
    });
    return {
        /**
         * Instantiate from the wasm response stream.
         * @param {Promise<Response>} responsePromise
         * @return {Promise<{instance:WebAssembly.Instance, module:WebAssembly.Module}>} wasm results object
         */
        instantiateFromStream: function (responsePromise) {
            var wasm = {
                binary: undefined,
                responsePromise: responsePromise,
            };
            resolveWasm(wasm);
            return wasmPromise;
        },
        /**
         * Instantiate from the wasm byte array.
         * @param {Uint8Array|ArrayBuffer} binary
         * @return {Promise<{instance:WebAssembly.Instance, module:WebAssembly.Module}>} wasm results object
         */
        instantiateFromBinary: function (binary) {
            var wasm = {
                binary: binary,
                responsePromise: undefined,
            };
            resolveWasm(wasm);
            return wasmPromise;
        },
        /**
         * Instantiate from the import object
         * @param {Object} importObject
         * @return {Promise<{instance:WebAssembly.Instance, module:WebAssembly.Module}>} wasm results object
         */
        instantiateFromImports: function (importObject) {
            resolveImports(importObject);
            return wasmPromise;
        },
    };
})();
/**
 * Gets the wasm binary by using an XHR.
 * @param {String} url location of the wasm
 * @return {Promise<Uint8Array>} the wasm binary response
 */
function _fetchWasmWithXHR(url) {
    return new Promise(function (resolve) {
        var wasmRequest = new XMLHttpRequest();
        wasmRequest.open('GET', url, true);
        wasmRequest.responseType = 'arraybuffer';
        wasmRequest.onload = function () {
            resolve(new Uint8Array(wasmRequest.response));
        };
        wasmRequest.send(null);
    });
}
function _fetchAndInstantiateWasm(url) {
    if (sas.ltjs.node) {
        // Load wasm from file system in Node.js
        return sas.ltjs.node.fs.promises.readFile(url).then(function (binary) {
            return _wasmLoader.instantiateFromBinary(binary);
        });
    }
    else if (_getConfigProperty('sas.servicePlatform') === 'WIP') {
        //
        // 9.4 based WIP servers do not work using the fetch api.
        // The root cause of that is still unknown, but for the time being
        // we are just falling back to using XHR.
        //
        return _fetchWasmWithXHR(url).then(function (binary) {
            return _wasmLoader.instantiateFromBinary(binary);
        });
    }
    else {
        return _wasmLoader.instantiateFromStream(fetch(url));
    }
}
/**
 * Adds a script tag to the DOM.
 * @param {string} elementId
 * @param {string} src URL to set as the src attribute.
 * @param {HTMLElement=} elementToAppendTo defaults to the script tag.
 * @param {Function=} onload Optional onload callback
 * @returns {HTMLElement} ltjsElement that was created or found in the DOM.
 * @private
 */
function _createScript(elementId, src, elementToAppendTo, onload, onerror) {
    if (sas.ltjs.node) {
        // Import the script directly in Node.js
        sas.ltjs.node.import(src).then(onload, onerror);
        // TODO: Return value no longer needed once ASM loading is removed
        return {};
    }
    var existingElement = document.getElementById(elementId);
    if (!existingElement) {
        var ltjsElement = document.createElement('script');
        ltjsElement.id = elementId;
        ltjsElement.type = 'text/javascript';
        ltjsElement.async = true;
        // development only. Allow the browser to surface the actual error
        // instead of "Script error" for scripts from a different domain.
        if (getUriParameter('sas-commons-configUrl') || getUriParameter('sas-commons-url')) {
            ltjsElement.crossOrigin = 'anonymous';
        }
        ltjsElement.src = src;
        if (onload) {
            ltjsElement.addEventListener('load', onload);
        }
        if (onerror) {
            ltjsElement.addEventListener('error', onerror);
        }
        if (!elementToAppendTo) {
            elementToAppendTo = document.getElementsByTagName('script')[0];
        }
        var parentNode = elementToAppendTo.parentNode;
        if (parentNode) {
            parentNode.insertBefore(ltjsElement, elementToAppendTo.nextSibling);
        }
        return ltjsElement;
    }
    else if (existingElement instanceof HTMLScriptElement) {
        return existingElement;
    }
    throw new Error("The element #".concat(elementId, " does not have type HTMLScriptElement"));
}
/**
 * Initialize Module.locateFile, which tells emscripten where to find wasm and NLS files
 */
function _initializeLocateFile() {
    if (!!ltjs.locateFile) {
        return;
    }
    ltjs.locateFile = function (path, _prefix) {
        // If someone has set sas.ltjs.ltjslibPath, use it to locate the files
        if (sas.ltjs.ltjslibPath) {
            return "".concat(sas.ltjs.ltjslibPath).concat(path.startsWith('ltjs-wasm') ? '' : 'assets/').concat(path);
        }
        // Otherwise, use getResourceURL
        var location = path.startsWith('ltjs-wasm')
            ? "".concat(BASE_RESOURCE_LOCATION, "/").concat(path) // ltjs-wasm files are at the top level
            : "".concat(getRelativeAssetPath()).concat(path); // NLS files are under assets
        return getResourceURL(location);
    };
}
function _loadWasm() {
    //
    // The emscripten scaffolding in ltjs.js checks to see if module.instantiateWasm
    // is defined, and if it is then it uses that to instantiate the wasm module.
    // Because the loading code exists both here and in the openui-framework pre-core,
    // we can't define _ltjsModule.instantiateWasm when this module loads, and must
    // wait until _loadWasm is called, to ensure that the correct version is defined.
    //
    ltjs.instantiateWasm = function (importObject, receiveInstance) {
        // tslint:disable-next-line:no-floating-promises
        _wasmLoader.instantiateFromImports(importObject).then(function (results) {
            receiveInstance(results.instance);
        });
        //
        // Return empty object for async loading
        //
        return {};
    };
    _initializeLocateFile();
    _createScript('ltjs-script', ltjs.locateFile('ltjs-wasm.js', ''));
    var tStart = performance.now();
    return _fetchAndInstantiateWasm(ltjs.locateFile('ltjs-wasm.wasm', '')).then(function (results) {
        var loadTime = performance.now() - tStart;
        console.log("LTJS load: WASM load time: ".concat(loadTime, " ms"));
        return results;
    });
}
function _setPropertiesInitialized() {
    sas.ltjslib.ltjs.ltjsNLSPropertiesInitialized = true;
    if (sas.ltjslib.ltjs.removeBlockingDependency) {
        sas.ltjslib.ltjs.removeBlockingDependency('ltjsNLSPropertiesInitialized');
    }
}
export function loadNLSProperties() {
    if (sas.ltjs.__hasNLSPropertyLoadStarted) {
        return;
    }
    if (sas.ltjs.jest || sas.ltjs.node) {
        // Preload bundles can't be loaded in Node.js.
        // The individual files will be read from the file system when they're needed.
        _setPropertiesInitialized();
        return;
    }
    sas.ltjs.__hasNLSPropertyLoadStarted = true;
    _initializeLocateFile();
    var loadersToLoad = [];
    var requestNLSData = function (locale) {
        // just use language and region for now
        var parts = locale.match(/(?:[^-]+)/g) || [];
        locale = parts.length > 1 ? parts[0] + '-' + parts[1] : parts[0];
        // make sure we aren't already loading this one and its not a pseudo locale
        // pseudo is handled by portable
        if (loadersToLoad.indexOf(locale) !== -1 || locale.endsWith('pseudo') || locale.endsWith('-XX')) {
            return;
        }
        loadersToLoad.push(locale);
        var rawModule = ltjsModule.module;
        var requestData = function () {
            var metadataResponse = null;
            var dataResponse = '';
            var metadataURL = ltjs.locateFile("NLS/".concat(locale, "-metadata.json"), '');
            var dataURL = ltjs.locateFile("NLS/".concat(locale, "-properties.txt"), '');
            rawModule.addBlockingDependency(metadataURL);
            rawModule.addBlockingDependency(dataURL);
            ajax({
                url: metadataURL,
                dataType: 'text',
            })
                .done(function (response) {
                if (response) {
                    metadataResponse = JSON.parse(response);
                }
                if (metadataResponse && dataResponse) {
                    ltjsModule.writeNLSBundleToFS(dataResponse, metadataResponse);
                }
                rawModule.removeBlockingDependency(metadataURL);
            })
                .fail(function () {
                if (locale === 'en-US') {
                    throw new Error('Unable to load en-US locale');
                }
                rawModule.removeBlockingDependency(metadataURL);
            });
            ajax({
                url: dataURL,
                dataType: 'text',
            })
                .done(function (response) {
                dataResponse = response;
                if (metadataResponse && dataResponse) {
                    ltjsModule.writeNLSBundleToFS(dataResponse, metadataResponse);
                }
                rawModule.removeBlockingDependency(dataURL);
            })
                .fail(function () {
                if (locale === 'en-US') {
                    throw new Error('Unable to load en-US locale');
                }
                rawModule.removeBlockingDependency(dataURL);
            });
        };
        if (rawModule.calledRun) {
            requestData();
        }
        else {
            if (!rawModule['preRun']) {
                rawModule['preRun'] = [];
            }
            rawModule['preRun'].push(requestData); // FS is not initialized yet, wait for it
        }
        // make sure we load the fallback locale for this locale
        // i.e. if the locale is fr-CA we also need to load fr
        // also, loaders do not exist for 'en' and 'zh'
        if (parts.length > 1 && parts[0] !== 'en' && parts[0] !== 'zh') {
            requestNLSData(parts[0]);
        }
    };
    requestNLSData('en-US');
    requestNLSData(getLocale());
    requestNLSData(getFormatLocale());
    _setPropertiesInitialized();
}
export function getRelativeAssetPath() {
    return BASE_RESOURCE_LOCATION + '/assets/';
}
function initializeLibPath(ltjsDependencies) {
    if (sas.ltjs.ltjslibPath || !sas.ltjs.isOpenUI || sas.ltjs.__useBaseResourceLocation) {
        return;
    }
    // Find highest order library. Order matters here.
    var resourceLocationSuffix = 'commons';
    if (ltjsDependencies.indexOf('graphbuilder') !== -1) {
        resourceLocationSuffix = 'graphbuilder';
    }
    else if (ltjsDependencies.indexOf('vav') !== -1) {
        resourceLocationSuffix = 'vav';
    }
    else if (ltjsDependencies.indexOf('gtl') !== -1) {
        resourceLocationSuffix = 'gtl';
    }
    // Register this new location with openui5 so that any request
    // for something in sas.ltjslib (BASE_RESOURCE_LOCATION)
    // resolves to request for that module at the new path.
    var newResourcePath = jQuery.sap.getResourcePath(BASE_RESOURCE_LOCATION, '') + '/ltjslib-' + resourceLocationSuffix;
    jQuery.sap.registerResourcePath(BASE_RESOURCE_LOCATION, newResourcePath);
}
export function loadScript(ltjsDependencies) {
    if (sas.ltjs.jest) {
        return;
    }
    if (!deferredLoading) {
        initializeLibPath(ltjsDependencies);
        // Make sure ltjs has not already been loaded or that we are not
        // in the middle of loading. ltjs-script is here in case a consumer copied VAV's boottask
        if (sas.ltjs.__hasBootStarted || (!sas.ltjs.node && document.getElementById('ltjs-script'))) {
            return;
        }
        sas.ltjs.__hasBootStarted = true;
        // a little defensive, but end of the release
        if (sas.ltjs.isOpenUI && sas && sas.eventBus && sas.eventBus.publish) {
            sas.eventBus.publish('ltjs', 'loading_started');
        }
        _loadWasm().catch(function (reason) {
            throw new Error("WebAssembly loading failed: ".concat(reason));
        });
    }
}
