// An array of bit masks, corresponding to the ith bit
// in a byte.  This is used in convertBitsetToBoolArray,
// which is used to pass BoolVectors across the bridge
// as a bitset (packed in Bytes).
var bitsetMask = [1, 2, 4, 8, 16, 32, 64, 128];
// Convert an array of numbers to be unsigned.
function convertValuesToUnsigned(values) {
    if (values) {
        for (var i = 0, l = values.length; i < l; i++) {
            values[i] = values[i] >>> 0;
        }
    }
    return values;
}
// Convert an array of values to booleans
function convertValuesToBool(values) {
    if (values) {
        for (var i = 0, l = values.length; i < l; i++) {
            values[i] = !!values[i];
        }
    }
    return values;
}
var LTJSModule = /** @class */ (function () {
    function LTJSModule(module) {
        var _this = this;
        /**
         * Set this to true when we get an abort from wasm
         */
        this.wasmCrashed = false;
        this.abortCallbacks = [];
        this._module = module;
        this.runtime.onAborts.push(this.onAbort.bind(this));
        if (!this._module.onAbort) {
            this._module.onAbort = function () {
                for (var _i = 0, _a = _this.runtime.onAborts; _i < _a.length; _i++) {
                    var cb = _a[_i];
                    cb();
                }
            };
        }
    }
    Object.defineProperty(LTJSModule.prototype, "runtime", {
        get: function () {
            if (!this.module._ltjsRuntime) {
                var runtime_1 = {
                    ltjsInitialized: false,
                    wrappersInitialized: false,
                    wrapperInitFunctions: [],
                    platformInitialized: false,
                    onPlatformInitialized: [],
                    onReadyFunctions: [],
                    initializeWrappers: function () {
                        runtime_1.wrappersInitialized = true;
                        for (var _i = 0, _a = runtime_1.wrapperInitFunctions; _i < _a.length; _i++) {
                            var wrapperInit = _a[_i];
                            wrapperInit();
                        }
                        runtime_1.wrapperInitFunctions.length = 0;
                    },
                    executeRunWhenInitializedCallbacks: function () {
                        runtime_1.ltjsInitialized = true;
                        for (var _i = 0, _a = runtime_1.onReadyFunctions; _i < _a.length; _i++) {
                            var cb = _a[_i];
                            cb();
                        }
                        runtime_1.onReadyFunctions.length = 0;
                    },
                    onAborts: [],
                    typeMapAvailable: false,
                    typeMapInitializers: [],
                    initializedTypemap: function () {
                        runtime_1.typeMapAvailable = true;
                        for (var _i = 0, _a = runtime_1.typeMapInitializers; _i < _a.length; _i++) {
                            var init = _a[_i];
                            init();
                        }
                        runtime_1.typeMapInitializers = [];
                    },
                    activeVisualizationManagers: new Set(),
                    themeOverrideCallbacks: new Set(),
                    dispatchBridgeMap: new Map(),
                };
                this.module._ltjsRuntime = runtime_1;
            }
            return this.module._ltjsRuntime;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LTJSModule.prototype, "module", {
        /**
         * The raw ltjs module.
         */
        get: function () {
            return this._module;
        },
        enumerable: false,
        configurable: true
    });
    LTJSModule.prototype.onAbort = function () {
        this.wasmCrashed = true;
        for (var _i = 0, _a = this.abortCallbacks; _i < _a.length; _i++) {
            var callback = _a[_i];
            callback();
        }
        // we're done here.
        this.abortCallbacks = [];
    };
    LTJSModule.prototype.addAbortCallback = function (callback) {
        if (this.wasmCrashed) {
            callback();
        }
        else {
            this.abortCallbacks.push(callback);
        }
    };
    LTJSModule.prototype.removeAbortCallback = function (callback) {
        this.abortCallbacks = this.abortCallbacks.filter(function (value) {
            return value !== callback;
        });
    };
    Object.defineProperty(LTJSModule.prototype, "exports", {
        // TODO: expose FS and Canvas2D through their own getters, instead of an exports object
        get: function () {
            return this._module.exports;
        },
        enumerable: false,
        configurable: true
    });
    LTJSModule.prototype.getStringContentsOfFile = function (path) {
        return this._module.getStringContentsOfFile(path);
    };
    LTJSModule.prototype.writeNLSBundleToFS = function (bundle, metadata) {
        return this._module.writeNLSBundleToFS(bundle, metadata);
    };
    /**
     * Set the callback function used to instantiate the wasm module.  The is the mechanism
     * for passing the imports object and instantiate module between our code and the
     * Emscripten runtime.
     * @param callback the wasm instantiation callback.
     */
    LTJSModule.prototype.setInstantiateWasmCallback = function (callback) {
        this._module.instantiateWasm = callback;
    };
    LTJSModule.prototype.setMemoryInitializerRequest = function (memoryRequest) {
        this._module.memoryInitializerRequest = memoryRequest;
    };
    LTJSModule.prototype.setUseAsm = function () {
        // keep ltjs.js from attempting to load WASM
        this._module.wasmJSMethod = 'asmjs';
    };
    LTJSModule.prototype.removeBlockingDependency = function (dependency) {
        if (this._module.removeBlockingDependency) {
            this._module.removeBlockingDependency(dependency);
        }
    };
    Object.defineProperty(LTJSModule.prototype, "deps", {
        // TODO: either make this a set* method or add a getter as well.
        set: function (value) {
            this._module.deps = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LTJSModule.prototype, "libraryLoadingCompleted", {
        set: function (value) {
            this._module.libraryLoadingCompleted = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LTJSModule.prototype, "onRuntimeInitialized", {
        set: function (value) {
            this._module.onRuntimeInitialized = value;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LTJSModule.prototype, "ltjsRuntimeInitialized", {
        set: function (value) {
            this._module.ltjsRuntimeInitialized = value;
        },
        enumerable: false,
        configurable: true
    });
    LTJSModule.prototype.setIsShuttingDown = function (shutdown) {
        // TODO: do we really need this check? copied from ltjsRuntime
        if (this._module.setIsShuttingDown) {
            this._module.setIsShuttingDown(shutdown);
        }
    };
    /**
     * Gets the portable type id of the wrapper object stored at the given closet address.
     * @param closetAddress the closet address of an existing wrapper object
     * @returns the type id of the wrapper object
     */
    LTJSModule.prototype.getTypeId = function (closetAddress) {
        return this._module._ltjs_commons_Closet_getTypeID(closetAddress);
    };
    /**
     * Commits the staged WrappableObject associated with the given closet key
     * to the closet and returns the closet address.
     * @param closetKey the closet key of staged object to commit to the closet
     * @returns the closet address of the object that has been committed
     */
    LTJSModule.prototype.commitStagedObject = function (closetKey) {
        return this._module._ltjs_commons_Closet_commitStagedObject(closetKey);
    };
    /**
     * Discards the staged WrappableObject associated with the given closet key.
     *
     * @param closetKey the closet key of object to discard
     */
    LTJSModule.prototype.discardStagedObject = function (closetKey) {
        this._module._ltjs_commons_Closet_discardStagedObject(closetKey);
    };
    /**
     * Get the size of the staged vector that is associated with the given closet key.
     *
     * @param closetKey the closet key of the staged vector
     */
    LTJSModule.prototype.getStagedVectorSize = function (closetKey) {
        return this._module._ltjs_commons_Closet_getStagedVectorSize(closetKey);
    };
    /**
     * Stage the WrappableObject that is at the given index of that staged WrappableObject vector.
     *
     * @param index the index of the object to stage
     */
    LTJSModule.prototype.stageObjectAt = function (index) {
        return this._module._ltjs_commons_Closet_stageObjectAt(index);
    };
    /**
     * Discards the staged vector associated with the given closet key.
     *
     * @param closetKey  the closet key of the vector to discard
     */
    LTJSModule.prototype.discardStagedVector = function (closetKey) {
        this._module._ltjs_commons_Closet_discardStagedVector(closetKey);
    };
    /**
     * @param {Array} values
     * @param {number} sizeOfTypeInBytes
     * @param {string} type
     * @param {function} closetCreateFunction
     * @param {boolean} isNumberArray
     * @returns {number} closet address
     */
    LTJSModule.prototype._copyValuesIntoCloset = function (values, sizeOfTypeInBytes, type, closetCreateFunction, isNumberArray) {
        if (isNumberArray === void 0) { isNumberArray = false; }
        if (!values || !type || !closetCreateFunction) {
            return 0;
        }
        var size = values.length;
        var buffer = this._module._malloc(size * sizeOfTypeInBytes);
        if (isNumberArray) {
            for (var i = 0; i < size; ++i) {
                var result = NaN;
                var val = values[i];
                if (typeof val === 'number') {
                    result = val;
                }
                else if (!isNaN(val)) {
                    var converted = parseFloat(val);
                    if (!isNaN(converted)) {
                        result = converted;
                    }
                }
                this._module.setValue(buffer + i * sizeOfTypeInBytes, result, type);
            }
        }
        else {
            for (var i = 0; i < size; ++i) {
                this._module.setValue(buffer + i * sizeOfTypeInBytes, values[i], type);
            }
        }
        var closetAddress = closetCreateFunction(size, buffer);
        this._module._free(buffer);
        return closetAddress;
    };
    /**
     * @param {number} sizeOfTypeInBytes
     * @param {string} type
     * @param {number} buffer
     * @param {number} numberOfElements
     * @returns {Array} values copied from closet
     */
    LTJSModule.prototype._copyValuesFromCloset = function (sizeOfTypeInBytes, type, buffer, numberOfElements) {
        // Return an empty array when numberOfElements is 0, regardless
        // of whether or not buffer is also 0.
        if (numberOfElements === 0) {
            return [];
        }
        if (!type || sizeOfTypeInBytes <= 0 || buffer === 0 || numberOfElements < 0) {
            return null;
        }
        var rc = [];
        for (var i = 0; i < numberOfElements; ++i) {
            rc[i] = this._module.getValue(buffer + i * sizeOfTypeInBytes, type);
        }
        return rc;
    };
    /**
     * @param {string} value
     * @returns {number} closetAddress of StringSP
     */
    LTJSModule.prototype.convertStringToC = function (value) {
        if (typeof value !== 'string') {
            return 0;
        }
        var buffer = this._module.stringToNewUTF8(value);
        var ret = this._module._ltjs_commons_Closet_createString(buffer);
        this._module._free(buffer);
        return ret;
    };
    /**
     * @param {number} closetAddress - closet address of a StringSP.
     * @returns {string} javascript string
     */
    LTJSModule.prototype.convertStringFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        var cStringPtr = this._module._ltjs_commons_Closet_getCString(closetAddress);
        return this._module.UTF8ToString(cStringPtr);
    };
    /**
     * @param {string[]} strings
     * @returns {number} Closet address of IntVectorPtr containing closetAddresses of StringSPs representing strings.
     */
    LTJSModule.prototype.convertStringArrayToC = function (strings) {
        if (!strings) {
            return 0;
        }
        var closetAddresses = [];
        for (var i = 0, length_1 = strings.length; i < length_1; ++i) {
            var closetAddress = this.convertStringToC(strings[i]);
            if (closetAddress < 0) {
                console.error('Conversion error for: ' + strings[i]);
            }
            closetAddresses[i] = closetAddress;
        }
        return this.convertIntArrayToC(closetAddresses);
    };
    /**
     * @param {number} closetAddress - closet address of an IntVectorPtr
     * @returns {string[]} converted values
     */
    LTJSModule.prototype.convertStringArrayFromC = function (closetAddress) {
        var closetAddresses = this.convertIntArrayFromC(closetAddress);
        if (!closetAddresses) {
            return null;
        }
        var rc = [];
        var size = closetAddresses ? closetAddresses.length : 0;
        for (var i = 0; i < size; ++i) {
            rc[i] = this.convertStringFromC(closetAddresses[i]);
        }
        this._module._ltjs_commons_Closet_releaseAllPrimitives(closetAddress);
        return rc;
    };
    /**
     * @param {number[]} values
     * @returns {number} closet address
     */
    LTJSModule.prototype.convertIntArrayToC = function (values) {
        if (!values) {
            return 0;
        }
        return this._copyValuesIntoCloset(values, 4, 'i32', this._module._ltjs_commons_Closet_createIntVector, true);
    };
    /**
     * @param {number} closetAddress - Closet address of an IntVectorPtr.
     * @returns {number[]} converted values
     */
    LTJSModule.prototype.convertIntArrayFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        return this._copyValuesFromCloset(4, 'i32', this._module._ltjs_commons_Closet_getAddressOfIntVector(closetAddress), this._module._ltjs_commons_Closet_getSizeOfIntVector(closetAddress));
    };
    /**
     * @param {number} closetAddress - Closet address of an MVector<int>.
     * @returns {number[]} converted values
     */
    LTJSModule.prototype.convertIntMVectorFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        return this._copyValuesFromCloset(4, 'i32', this._module._ltjs_commons_Closet_getAddressOfIntMVector(closetAddress), this._module._ltjs_commons_Closet_getSizeOfIntMVector(closetAddress));
    };
    /**
     * @param {number[]} values - Array containing ONLY unsigned.
     * @returns {number} closetAddress
     */
    LTJSModule.prototype.convertUnsignedArrayToC = function (values) {
        if (!values) {
            return 0;
        }
        return this._copyValuesIntoCloset(values, 4, 'i32', this._module._ltjs_commons_Closet_createUnsignedVector, true);
    };
    /**
     * @param {number} closetAddress - Closet address of an UnsignedVectorPtr
     * @returns {number[]} converted values
     */
    LTJSModule.prototype.convertUnsignedArrayFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        return convertValuesToUnsigned(this._copyValuesFromCloset(4, 'i32', this._module._ltjs_commons_Closet_getAddressOfUnsignedVector(closetAddress), this._module._ltjs_commons_Closet_getSizeOfUnsignedVector(closetAddress)));
    };
    /**
     * @param {number} closetAddress - Closet address of an MVector<unsigned int>
     * @returns {number[]} converted values
     */
    LTJSModule.prototype.convertUnsignedMVectorFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        return convertValuesToUnsigned(this._copyValuesFromCloset(4, 'i32', this._module._ltjs_commons_Closet_getAddressOfUnsignedMVector(closetAddress), this._module._ltjs_commons_Closet_getSizeOfUnsignedMVector(closetAddress)));
    };
    /**
     * @param {number[]} values - Array containing ONLY doubles.
     * @returns {number} closetAddress
     */
    LTJSModule.prototype.convertDoubleArrayToC = function (values) {
        if (!values) {
            return 0;
        }
        return this._copyValuesIntoCloset(values, 8, 'double', this._module._ltjs_commons_Closet_createDoubleVector, true);
    };
    /**
     * @param {number} closetAddress - Closet address of a DoubleVectorPtr
     * @returns {number[]} converted values
     */
    LTJSModule.prototype.convertDoubleArrayFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        return this._copyValuesFromCloset(8, 'double', this._module._ltjs_commons_Closet_getAddressOfDoubleVector(closetAddress), this._module._ltjs_commons_Closet_getSizeOfDoubleVector(closetAddress));
    };
    /**
     * @param {number} closetAddress - Closet address of an MVector<SASDouble>
     * @returns {number[]} converted values
     */
    LTJSModule.prototype.convertDoubleMVectorFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        return this._copyValuesFromCloset(8, 'double', this._module._ltjs_commons_Closet_getAddressOfDoubleMVector(closetAddress), this._module._ltjs_commons_Closet_getSizeOfDoubleMVector(closetAddress));
    };
    /**
     * @param {number[]} values - Array containing ONLY floats.
     * @returns {number} closetAddress
     */
    LTJSModule.prototype.convertFloatArrayToC = function (values) {
        if (!values) {
            return 0;
        }
        return this._copyValuesIntoCloset(values, 4, 'float', this._module._ltjs_commons_Closet_createFloatVector, true);
    };
    /**
     * @param {number} closetAddress - Closet address of a FloatVectorPtr
     * @returns {number[]} converted values
     */
    LTJSModule.prototype.convertFloatArrayFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        return this._copyValuesFromCloset(4, 'float', this._module._ltjs_commons_Closet_getAddressOfFloatVector(closetAddress), this._module._ltjs_commons_Closet_getSizeOfFloatVector(closetAddress));
    };
    /**
     * @param {number} closetAddress - Closet address of an MVector<SASFloat>
     * @returns {number[]} converted values
     */
    LTJSModule.prototype.convertFloatMVectorFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        return this._copyValuesFromCloset(4, 'float', this._module._ltjs_commons_Closet_getAddressOfFloatMVector(closetAddress), this._module._ltjs_commons_Closet_getSizeOfFloatMVector(closetAddress));
    };
    /**
     * @param {boolean[]} values - Array containing ONLY Booleans.
     * @returns {number} closetAddress
     */
    LTJSModule.prototype.convertBoolArrayToC = function (values) {
        values = convertValuesToBool(values);
        if (!values) {
            return 0;
        }
        return this._copyValuesIntoCloset(values, 1, 'i8', this._module._ltjs_commons_Closet_createBoolVector);
    };
    /**
     * @param {number} closetAddress - Closet address of a BoolVectorPtr
     * @returns {Array} converted values
     */
    LTJSModule.prototype.convertBoolArrayFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        // Convert the bool vector to a packed vector<unsigned char> of bits
        var bitsetClosetAddress = this._module._ltjs_commons_Closet_getBoolVectorAsBitset(closetAddress);
        var bitsetAddress = this._module._ltjs_commons_Closet_getAddressOfBitset(bitsetClosetAddress);
        var bitsetSize = this._module._ltjs_commons_PrimitiveCloset_getSizeOfBoolVector(closetAddress);
        var boolArray = this.convertBitsetToBoolArray(bitsetAddress, bitsetSize);
        // Cleanup the temporary bitset that is in the closet
        this._module._ltjs_commons_Closet_releasePrimitive(bitsetClosetAddress);
        return boolArray;
    };
    /**
     * @param {number} closetAddress - Closet address of an MVector<bool>
     * @returns {Array} converted values
     */
    LTJSModule.prototype.convertBoolMVectorFromC = function (closetAddress) {
        if (!closetAddress) {
            return null;
        }
        // Convert the bool vector to a packed vector<unsigned char> of bits
        var bitsetClosetAddress = this._module._ltjs_commons_Closet_getBoolMVectorAsBitset(closetAddress);
        var bitsetAddress = this._module._ltjs_commons_Closet_getAddressOfBitset(bitsetClosetAddress);
        var bitsetSize = this._module._ltjs_commons_PrimitiveCloset_getSizeOfBoolMVector(closetAddress);
        var boolArray = this.convertBitsetToBoolArray(bitsetAddress, bitsetSize);
        // Cleanup the temporary bitset that is in the closet
        this._module._ltjs_commons_Closet_releasePrimitive(bitsetClosetAddress);
        return boolArray;
    };
    LTJSModule.prototype.convertBitsetToBoolArray = function (bitsetAddress, bitsetSize) {
        if (bitsetSize === 0) {
            return [];
        }
        var heap = this._module.HEAP8;
        if (!heap || bitsetAddress === 0 || bitsetSize < 0) {
            return null;
        }
        var boolArray = [];
        for (var i = 0; i < bitsetSize; ++i) {
            // Retrieve the byte from the heap.
            // The location is the base address plus byte offset
            // of the ith bit.  8 bits per byte -> offset = Math.floor(i/8)
            var byteValue = heap[bitsetAddress + Math.floor(i / 8)];
            // Determine the value of the ith bit by first calculating
            // the offset within the current byteValue (i%8), lookup
            // the correct mask to use for that bit position, and
            // bitwise AND that mask with the byteValue.  This
            // returns the actual number which is then converted
            // to a bool using !! operator.
            boolArray[i] = !!(byteValue & bitsetMask[i % 8]); // eslint-disable-line no-bitwise
        }
        return boolArray;
    };
    LTJSModule.prototype.convertObjectToC = function (closetObject) {
        return closetObject ? closetObject.getClosetAddress() : 0;
    };
    /**
     * @param {ClosetObject[]} array - Array containing ClosetObject subclasses.
     * @returns {number} closetAddress
     */
    LTJSModule.prototype.convertObjectArrayToC = function (array) {
        if (!array) {
            return 0;
        }
        var arrayOfClosetAddresses = [];
        for (var i = 0, size = array.length; i < size; ++i) {
            arrayOfClosetAddresses[i] = this.convertObjectToC(array[i]);
        }
        return this.convertIntArrayToC(arrayOfClosetAddresses);
    };
    /**
     * @param {number} closetAddress
     * @returns {Array} Array of float arrays containing geometry information.
     */
    LTJSModule.prototype.convertGeometryArrayFromC = function (closetAddress) {
        var arrayOfClosetAddresses = this.convertIntArrayFromC(closetAddress);
        if (!arrayOfClosetAddresses) {
            return null;
        }
        var rc = [];
        for (var i = 0, length_2 = arrayOfClosetAddresses.length; i < length_2; ++i) {
            rc[i] = this.convertFloatArrayFromC(arrayOfClosetAddresses[i]);
        }
        this.releaseAllPrimitives(closetAddress);
        return rc;
    };
    /**
     * @param {Array} array - Array of float arrays.
     * @returns {number} closet address
     */
    LTJSModule.prototype.convertGeometryArrayToC = function (array) {
        if (!array) {
            return 0;
        }
        var arrayOfClosetAddresses = [];
        for (var i = 0, length_3 = array.length; i < length_3; ++i) {
            arrayOfClosetAddresses[i] = this.convertFloatArrayToC(array[i]);
        }
        return this.convertIntArrayToC(arrayOfClosetAddresses);
    };
    /**
     * @returns {number} total number of objects in the c++ WrappableCloset
     */
    LTJSModule.prototype.getWrappableClosetSize = function () {
        return this._module._ltjs_commons_Closet_getWrappableClosetSize();
    };
    /**
     * @returns {number} total number of objects in the c++ PrimitiveCloset
     */
    LTJSModule.prototype.getPrimitiveClosetSize = function () {
        return this._module._ltjs_commons_Closet_getPrimitiveClosetSize();
    };
    /**
     * Removes an object from the Closet.
     * @param {number} closetAddress
     */
    LTJSModule.prototype.releaseWrappable = function (closetAddress) {
        // console.log('ClosetObject::destroy -- before jni call');
        this._module._ltjs_commons_Closet_releaseWrappable(closetAddress);
        // console.log('ClosetObject::destroy -- current closet size = ' + ClosetObject.getClosetSize());
        // console.log('ClosetObject::destroy -- after jni call');
    };
    /**
     * Removes a primitive (ARRAY, STRING, etc) from the closet
     * @param {number} closetAddress
     */
    LTJSModule.prototype.releasePrimitive = function (closetAddress) {
        // console.log('ClosetObject::destroy -- before jni call');
        this._module._ltjs_commons_Closet_releasePrimitive(closetAddress);
        // console.log('ClosetObject::destroy -- current closet size = ' + ClosetObject.getClosetSize());
        // console.log('ClosetObject::destroy -- after jni call');
    };
    /**
     * Removes a collection of primitives from the c++ primitive closet,
     * referenced by the closet address of the vector<int> that holds the
     * primitive closet addresses.  The vector<int> is also removed from
     * the primitive closet
     *
     * @param {number} closetAddress closet address of a vector<int> containing primitive closet addresses
     */
    LTJSModule.prototype.releaseAllPrimitives = function (closetAddress) {
        this._module._ltjs_commons_Closet_releaseAllPrimitives(closetAddress);
    };
    /**
     * Removes a collection of WrappableObjects from the c++ closet,
     * referenced by an array of closet addresses.
     * @param {Array} wrappableAddresses closet addresses of objects to remove from the closet
     */
    LTJSModule.prototype.releaseAllWrappables = function (wrappableAddresses) {
        var wrappableAddresses_jni = this.convertIntArrayToC(wrappableAddresses);
        // Note that releaseAllWrappables also releases the vector from the primitive closet.
        this._module._ltjs_commons_Closet_releaseAllWrappables(wrappableAddresses_jni);
    };
    LTJSModule.prototype.getClosetKey = function (closetAddress) {
        return this._module._ltjs_commons_Closet_getAddress(closetAddress);
    };
    LTJSModule.prototype.closetObjectsEqual = function (addr1, addr2) {
        return this._module._ltjs_commons_Closet_equals(addr1, addr2);
    };
    return LTJSModule;
}());
export default LTJSModule;
