var moduleNameRegex = /sas\/ltjs\/([^\/]+).*\/([^\/]+)/;
// Transform the `sas/ltjs/{namespace}/**/{name}` module path string
// into the `{namespace}::{name}` format.
function modulePathToNamespacedName(path) {
    var matches = path.match(moduleNameRegex);
    if (matches && matches.length === 3) {
        var namespace = matches[1];
        var module = matches[2];
        return "".concat(namespace, "::").concat(module);
    }
    throw new Error("Unexpected LTJS module path structure: ".concat(path));
}
var TypeMap = /** @class */ (function () {
    function TypeMap() {
        this._typeMap = new Map();
        // Map from namespaced module name, i.e. {namespace}::{name}, to module.
        this._moduleMap = new Map();
    }
    TypeMap.prototype.initFromModuleTypeMap = function (rawTypeMap) {
        var length = rawTypeMap.length;
        for (var typeId = 0; typeId < length; typeId++) {
            var entry = rawTypeMap[typeId];
            if (entry) {
                var modulePath = entry[0], parentTypeId = entry[1];
                var moduleName = modulePathToNamespacedName(modulePath);
                this._typeMap.set(typeId, {
                    typeId: typeId,
                    moduleName: moduleName,
                    modulePath: modulePath,
                    parentTypeId: parentTypeId,
                });
            }
        }
    };
    TypeMap.prototype.getTypeEntry = function (typeId) {
        return this._typeMap.get(typeId);
    };
    TypeMap.prototype.addClassToModuleMap = function (moduleName, classToAdd) {
        this._moduleMap.set(moduleName, classToAdd);
    };
    TypeMap.prototype.getModuleByName = function (moduleName) {
        return this._moduleMap.get(moduleName);
    };
    return TypeMap;
}());
export default TypeMap;
