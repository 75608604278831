var isOpenUI = sas.ltjs.isOpenUI;
var addressParser;
var urlParms;
export function getResourceURL(url) {
    if (isUrlCrossDomain(url)) {
        if (isOpenUI) {
            // tslint:disable-next-line:no-unsafe-any
            return sas.hc.ui.core.util.getProxyUrl(url, false);
        }
        return url;
    }
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
    }
    if (url.startsWith('./resources/')) {
        url = url.substring(12);
    }
    else if (url.startsWith('/resources/')) {
        url = url.substring(11);
    }
    if (url.startsWith('/')) {
        url = url.substring(1);
    }
    if (sas.ltjs.getResourceUrl) {
        var resource = sas.ltjs.getResourceUrl(url);
        if (resource) {
            return resource;
        }
    }
    var resourcePath;
    if (isOpenUI) {
        // tslint:disable-next-line:no-unsafe-any
        resourcePath = jQuery.sap.getResourcePath(url, '');
        if (url.endsWith('/') && !resourcePath.endsWith('/')) {
            resourcePath += '/';
        }
        return resourcePath;
    }
    if (!sas.ltjs.resourceUrl) {
        console.warn("sas.ltjs.resourceUrl not configured, defaulting to \"/\"");
        sas.ltjs.resourceUrl = '/';
    }
    return "".concat(sas.ltjs.resourceUrl).concat(sas.ltjs.resourceUrl.endsWith('/') ? '' : '/').concat(url);
}
export function isUrlCrossDomain(url) {
    if (sas.ltjs.node) {
        return false;
    }
    if (isOpenUI) {
        // tslint:disable-next-line:no-unsafe-any
        return sas.hc.ui.core.util.isUrlCrossDomain(url);
    }
    if (!addressParser) {
        addressParser = document.createElement('a');
    }
    //This shouldn't fail, but browsers...
    try {
        addressParser.href = url;
    }
    catch (e) {
        console.warn('Failed to parse url: ', url);
        return true;
    }
    //IE returns emty string on relative urls.
    if (!addressParser.protocol) {
        return false;
    }
    if (addressParser.protocol !== location.protocol || addressParser.hostname !== location.hostname) {
        return true;
    }
    // copied from sas.hc.ui.core.util.isUrlCrossDomain
    // https://url.spec.whatwg.org/#concept-url-port
    // https://url.spec.whatwg.org/#port-state
    // If buffer is equal to url's scheme's default port, set buffer to the empty string.
    // Hence set port to empty string if its a default port as per https://url.spec.whatwg.org/#default-port
    // No need to check for the protocol again as it was taken care in previous checks.
    var port = addressParser.port === '80' || addressParser.port === '443' ? '' : addressParser.port;
    // check if the ports are same.
    // ports are same even when uri port is undefined and location.port is empty string
    // tslint:disable-next-line:strict-type-predicates
    if ((port !== null && port !== location.port) || (port === null && location.port !== '')) {
        return true;
    }
    // https://developer.mozilla.org/en-US/docs/Web/Security/Same-origin_policy
    // for now ignore if both apps are from different web context.
    return false;
}
/**
 * Returns the value of the URI parameter with the given parameterName.
 * @param {string} [parameterName] The name of the URI parameter.
 * @param {boolean|number|string|undefined} [defaultValue=undefined] Optional default value.
 * @returns {boolean|number|string|} True, false, or default if parameter missing or cannot be converted to true or false.
 */
export function getUriParameter(parameterName, defaultValue) {
    // Return defaultValue when running in node
    if (!globalThis.location) {
        return defaultValue;
    }
    if (!urlParms) {
        urlParms = {};
        var pageURL = globalThis.location.search.substring(1);
        var sURLVariables = pageURL.split('&');
        for (var i = 0; i < sURLVariables.length; i++) {
            var parameter = sURLVariables[i].split('=');
            urlParms[parameter[0]] = decodeURIComponent(parameter[1]);
        }
    }
    var parameterValue = urlParms[parameterName];
    return parameterValue || defaultValue;
}
function _getUriParameterBoolean(sValue, defaultValue) {
    if (sValue) {
        sValue = sValue.toLowerCase();
        if (sValue === 'true' || sValue === 'yes' || sValue === '1') {
            defaultValue = true;
        }
        else if (sValue === 'false' || sValue === 'no' || sValue === '0') {
            defaultValue = false;
        }
    }
    return defaultValue;
}
export function getUriParameterBoolean(parameterName, defaultValue) {
    var parameterValue = getUriParameter(parameterName);
    return _getUriParameterBoolean(parameterValue, defaultValue);
}
